import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/NotFound'
  },
  {
    name: 'eform',
    component: () => import('./view/eform'),
    meta: {
      title: '主页'
    },
    children: [
      {
        path: '/:countryPath/:brand/EnterOpt/:lang',
        component: () =>
          import('./view/eform/EnterOpt'),
        name: 'EnterOpt',
        meta: {
          title: 'Enter Otp'
        }
      },
      {
        path: '/:countryPath/:brand/EnterEmail/:lang',
        component: () =>
          import('./view/eform/EnterEmail'),
        name: 'EnterEmail',
        meta: {
          title: 'Enter Email'
        }
      },
      {
        path: '/:countryPath/:brand/EnterNumber/:lang',
        component: () =>
          import('./view/eform/EnterNumber'),
        name: 'EnterNumber',
        meta: {
          title: 'Enter Number'
        }
      },
      {
        path: '/:countryPath/:brand/Registration/:lang',
        component: () =>
          import('./view/eform/Registration'),
        name: 'Registration',
        meta: {
          title: 'Registration'
        }
      },
      {
        path: '/:countryPath/:brand/RegistSuccess/:lang',
        component: () =>
          import('./view/eform/RegistSuccess'),
        name: 'RegistSuccess',
        meta: {
          title: 'Registered successfully'
        }
      },
      {
        path: '/NotFound',
        component: () =>
          import('./view/eform/NotFound'),
        name: 'NotFound',
        meta: {
          title: 'Page not using'
        }
      },
      {
        path: '/:countryPath/QRcodes/:lang',
        component: () =>
          import('./view/eform/QRcodes'),
        name: 'QRcodes',
        meta: {
          title: 'QRcodes'
        }
      }
    ]
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ mode: 'hash', routes: routes });

router.beforeEach((to, from, next) => {

  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }

  const pageArr = ["NotFound","Registration","QRcodes","EnterNumber","EnterOpt","RegistSuccess","EnterEmail"]
  if (pageArr.includes(to.name)) {
    next()
  } else {
    next({ path: '/NotFound' })
  }
});


export {
  router
};
