import Vue from 'vue';
import App from './App';
import { router } from './router';
import { Toast, Loading, Cell, Image as VanImage, CountDown, Divider } from 'vant'
import VueI18n from 'vue-i18n'
// import QRcode from './components/QRCode'

// Vue.use(QRcode);

Vue.use(Toast)
Vue.use(Loading)
Vue.use(VueI18n)
Vue.use(Cell)
Vue.use(VanImage)
Vue.use(CountDown)
Vue.use(Divider)

//多语言实例
const i18n = new VueI18n({
  locale:(function(){
    return 'en'
  }()),
  messages:{
    'en':require('./assets/language/en'), //英文语言包
    'kr': require('./assets/language/kr'),
    'jpn': require('./assets/language/jpn'),
  }
})

// string.format
String.prototype.format = function() {
  var formatted = this;
  for( let arg in arguments ) {
      formatted = formatted.replace("{" + arg + "}", arguments[arg]);
  }
  return formatted;
};

new Vue({
  router,
  el: '#app',
  i18n,
  render: h => h(App)
});
